import { defineStore } from "pinia";
import axios from "axios";
import type { ItemsListResponse } from "@/_helpers/interfaces/listing/items-list-response.interface";
import type { LogInList } from "@/_helpers/interfaces/history-logs/logs-in-list.interface";
import type { LogsListPaginationOptions } from "@/_helpers/interfaces/history-logs/logs-list-pagination-options.interface";

export const useHistoryLogStore = defineStore("history-log", () => {
  const getLogsList = async (
    options: LogsListPaginationOptions
  ): Promise<ItemsListResponse<LogInList>> => {
    try {
      const { data } = await axios.get(`/common/history/`, { params: options });

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const exportLogs = async (
    options: LogsListPaginationOptions
  ): Promise<any> => {
    try {
      const { data } = await axios.get("/common/history/export/", {
        params: options,
      });
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  return {
    getLogsList,
    exportLogs,
  };
});
